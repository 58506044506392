@charset "utf-8";
body {
    color: #000;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4;
}

.right-content {
    padding-top: 0
}

.nav-wrapper {
    background: #cbc9c9 url(../images/nav-bg.jpg) repeat-x top center;
    border-bottom: 1px solid #a4a4a4
}

.nav-wrapper ul li {
    border: none
}

.nav-wrapper ul li:hover a,
.nav-wrapper ul li.hover a,
.nav-wrapper ul li.active a {
    background: #32789d url(../images/nav-menu-hover-bg.jpg) repeat-x top center;
    color: #fff
}

.nav-wrapper ul li a {
    padding: 0 21px!important
}

.nav-wrapper ul li.hasnav {
    background: url(../images/blt-bottom.png) no-repeat 96% center
}

.nav-wrapper ul ul {
    background: #32789d
}

.nav-wrapper ul ul li.active a,
.nav-wrapper ul ul li.hover a,
.nav-wrapper ul ul li a:hover {
    background: #e09912
}

.nav-wrapper ul li a {
    color: #000
}

.gray-bg {
    background-color: #ebebeb;
    padding-bottom: 110px !important
}

.slides>li {
    position: relative
}

.slide-caption {
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1000;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    /* line-height: 1.6em; */
    font-size: 16px;
    display: inline-block;
    padding: 9px;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    width: 100%;
}

body~.sub-nav {
    right: 0;
}

.tables-wrapper .four_content h2 {
    font-size: 20px;
    text-align: center;
    margin: 5px;
}


/*.flex-direction-nav a{height:58px;width:48px;padding:14px}*/

.slide-caption p {
    color: #0f0f0f;
    font-weight: 400;
    font-size: 100%;
    line-height: 115%
}

.slide-caption a {
    color: #000;
    font-weight: 600;
    text-decoration: none;
    font-size: 100%
}

.slide-caption a:hover {
    text-decoration: underline
}

.slide-caption h2 span {
    display: block;
    font-weight: 300
}

ul#nav li a i.fa-home {
    font-size: 150%
}

.body-block.whats-new-area h2 {
    color: #fff;
    margin-bottom: 0;
    margin-top: 17px
}

.mii-logo {
    display: inline-block
}

.gallery-wrapper {
    background: url(../images/ico-search.png) repeat;
    min-height: 220px;
    padding: 40px 0 50px
}

.gallery-area a {
    background: #937048;
    border: 0 none;
    color: #fff;
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    text-transform: uppercase
}

.gallery-area h3 {
    background: #000000;
    color: #fff;
    float: left;
    padding: 10px 20px;
    text-transform: uppercase
}

.gallery-holder ul.slides {
    float: left;
    width: 74.5%;
    padding: 1px
}

.gallery-holder ol.flex-control-nav.flex-control-thumbs {
    float: left;
    margin: 0 0 0 1.5%;
    padding: 0;
    width: 23.2%
}

.gallery-holder .flex-direction-nav {
    display: none
}

.gallery-holder .flex-control-thumbs li {
    margin: 0 0 5.6%;
    padding: 0;
    width: 100%
}

.bttn-view {
    position: absolute;
    top: 0;
    right: 5px
}

.gallery-right {
    position: relative;
    width: 30%;
    float: right;
    padding-top: 70px
}

.bttn-intrct {
    border: 1px solid #ffd800;
    display: inline-block;
    padding: 15px 50px 15px 20px;
    color: #ffd800;
    font-size: 120%;
    font-weight: 600;
    position: relative;
    margin-bottom: 20px
}

.bttn-intrct i {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 200%
}

.bttn-intrct:hover {
    background: #ffd800;
    color: #333
}

.gallery-right ul li {
    color: #fff;
    font-size: 110%;
    background: url(../images/blt-green.png) no-repeat 0 4px;
    padding: 0 0 15px 20px
}

.gallery-right ul li a {
    color: #fff
}

.gallery-right ul li a:hover {
    text-decoration: underline
}

.gallery-heading {
    float: left;
    left: 1px;
    position: absolute;
    top: 1px;
    z-index: 99999;
    opacity: .6
}

.gallery-area .gallery-heading {
    opacity: 1
}

.banner-wrapper .flex-direction-nav a {
    opacity: 1;
}

.banner-wrapper .flex-direction-nav {
    position: absolute;
    right: 10.5%;
    top: auto;
    bottom: 40px;
}

.banner-wrapper .flex-direction-nav .flex-next,
.banner-wrapper .flexslider:hover .flex-direction-nav .flex-next {
    right: -77px
}

.banner-wrapper .flex-direction-nav .flex-prev,
.banner-wrapper .flexslider:hover .flex-direction-nav .flex-prev {
    left: -43px
}

.banner-wrapper .flex-direction-nav a::before {
    font-size: 15px;
    font-weight: 300;
    background: #19181f;
    display: inline-block;
    color: #FFF;
    border-radius: 0;
    text-shadow: none;
    line-height: 30px;
    width: 40px;
    text-align: center;
    padding: 5px
}

.gallery-container {
    padding: 50px 0
}

.gallery-area {
    width: 72%;
    float: left;
    position: relative
}

.gallery-holder .slide-caption {
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    color: #FFF;
    bottom: 0;
    font-size: 14px;
    padding: 10px;
    max-width: inherit;
}

.youtube-video {
    width: 100%;
    position: relative;
    padding-bottom: 2%
}

.mar-bott {
    margin-bottom: 15px
}

.youtube-video iframe {
    width: 100%;
    position: absolute;
    height: 100%
}

.left-menu {
    width: 25%;
    float: left
}

.right-content {
    float: right
}

.breadcam {
    padding-bottom: 10px;
    padding-top: 10px;
    display: block;
    border-bottom: 1px solid #efefef;
    margin-bottom: 20px
}

.breadcam li {
    list-style-type: none;
    display: inline-block;
    font-size: 90%;
    color: #000
}

.breadcam li a {
    display: block;
    background: url(../images/breadcam-arrow.png) no-repeat right center;
    padding-right: 10px;
    color: #015296
}

.breadcam li:last-child a {
    background: none
}

.breadcam li.current {
    color: #000
}

.inner-wrapper .body-container {
    padding-top: 0
}

.inner-wrapper .left-menu {
    width: 25%;
    float: left
}

.inner-wrapper .inner-content {
    width: 100%;
    float: left
}

.inner-wrapper .inner-content p {
    text-align: justify
}

.left-menu ul {
    display: block;
    width: 100%;
    border-right: 4px solid #ededed;
    padding-right: 20px
}

.left-menu ul li a {
    background: url(../images/ico-search.png) no-repeat 9px 16px;
    padding: 10px 0 10px 30px;
    display: block;
    font-weight: 600
}

.left-menu ul li {
    margin-bottom: 10px
}

.left-menu ul li:hover a,
.left-menu ul li.active a {
    background: url(../images/ico-search.png) no-repeat #efefef 9px 16px
}

.left-menu ul li ul {
    padding-right: 0;
    width: 100%;
    border: none
}

.left-menu ul li ul li a {
    margin-left: 20px
}

.left-menu ul li ul li {
    background: none
}

.common-right ul>li>.de-lag {
    padding: 3px 6px;
    font-size: 120%;
    display: none;
}

.hindi.cmf_lan.d-hide {
    padding-top: 6px;
}

.left-menu ul li:hover ul li a,
.left-menu ul li.active ul li a,
.left-menu ul li ul li:hover a,
.left-menu ul li ul li.active a {
    background: url(../images/ico-search.png) no-repeat 9px 16px
}

.left-menu ul li ul li a:hover {
    background: url(../images/ico-search.png) no-repeat 9px 16px;
    text-decoration: underline
}

.left-menu ul li.active a,
.left-menu ul li:hover a {
    color: #004242
}

.left-menu ul li a:hover {
    text-decoration: none
}

.inner-content h2 {
    font-size: 180%;
    font-weight: 700;
    padding-bottom: 20px;
    color: #212020;
    /* margin-top: -9px; */
}

.inner-content .boxed-text h3,
.boxed-text h3 {
    font-size: 130%;
    font-weight: 700;
    padding-bottom: 20px;
    color: #212020;
}

.boxed-text h4 {
    font-size: 110%;
    font-weight: 700;
    padding-bottom: 20px;
    color: #212020;
}

.boxed-text .clearfix li {
    margin-left: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.boxed-text .clearfix li:not(:last-child) {
    border-bottom: 1px solid #dadada;
}

.more {
    clear: both;
    color: #947046;
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 600;
    border: 1px solid #947046;
    display: inline-block;
    margin-top: 10px;
    box-shadow: 0px 0px 5px 0px #888;
}

.more:hover {
    border: 1px solid #666;
    color: #fff;
    background-color: #947046;
    box-shadow: 0px 0px 2px 0px #aaa;
}

.color-border {
    width: 40%;
    min-width: 150px;
    height: 3px;
    background: #004242;
    margin-bottom: 20px
}

.inner-content h3 {
    font-weight: 700;
    font-size: 110%;
    padding-bottom: 20px;
    color: #000;
    padding-top: 5px
}

.inner-content p {
    font-size: 100%
}

.inner-content ul {
    padding-left: 10px;
    padding-top: 0;
    padding-bottom: 22px
}

.inner-content ul li {
    background: url(../images/ico-list-dbl-arrow-red.png) no-repeat 0 5px;
    padding-left: 18px;
    margin-bottom: 10px;
    margin-right: 4%
}

.inner-content ul li:last-child {
    margin-bottom: 0
}

.inner-content hr {
    color: #ebebeb;
    background: #ebebeb;
    margin: 4px 0 17px;
}

.inner-content img.left {
    float: left;
    padding: 4px;
    border: 1px solid #ebebeb;
    margin: 0 20px 20px 0
}

.inner-content img.right {
    float: right;
    padding: 4px;
    border: 1px solid #ebebeb;
    margin: 0 0 20px 20px
}

.inner-content {
    padding-bottom: 20px
}

.infocus,
.tabs-box,
.external-link {
    border-radius: 1px
}

.body-container {
    padding: 0
}

a.read-more {
    color: #937048;
    text-decoration: none;
    font-size: 100%;
    padding-left: 20px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 13px;
    display: block;
}

a.read-more:hover {
    color: #000;
}

.whats-new-maincontainer {
    width: 100%;
    float: left;
    margin: 0 0px
}

.complaint {
    width: 36%;
    float: left;
    margin-right: 30px
}

.otonomus-container {
    width: 36%;
    float: right;
    background: #77a6be;
    padding: 20px
}

.whats-new {
    color: #1a1a1a;
    float: left;
    margin: 0 2.5% 0 0;
    padding: 20px 0;
    width: 60%
}

.whats-new h3 {
    background: rgba(0, 0, 0, 0) url(../images/whats-new.png) no-repeat scroll 0 6px;
    color: #000;
    font-size: 160%;
    font-weight: 600;
    margin-bottom: 15px;
    padding-bottom: 8px;
    padding-left: 30px;
    text-transform: uppercase
}

.whats-new h3 strong {
    font-weight: 700
}

.whats-new h3 a {
    display: inline-block;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #b8e0ec;
    font-size: 70%;
    font-weight: 300;
    vertical-align: middle
}

.whats-new h3 a:hover {
    text-decoration: underline
}

.whats-new .viewall {
    background: #32789d;
    padding: 6px 15px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    margin-top: 15px;
    display: inline-block;
    text-transform: uppercase
}

.whats-new .viewall:hover {
    background: #145171
}

.minister {
    background: #fff none repeat scroll 0 0;
    float: right;
    width: 25%;
    /*border:1px solid #e1dddd*/
}

.bttn {
    display: inline-block;
    padding: .6em 1.2em;
    border-radius: 0;
    color: #937048;
    font-weight: bold;
    margin-top: 1.1em;
    font-size: 1.1em
}

.bttn:hover {
    background: #000
}

.text-heading {
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    color: #937048
}

.color-blue {
    color: #000000
}

.blue-bg {
    background: #937048
}

.minister-box:first-child {
    border-bottom: 1px solid #ddd
}

.minister-box {
    padding: 20px;
}

.minister-box .minister-image {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
    text-align: center;
}

.minister img {
    display: inline-block;
    border: 1px solid #d8d8d8;
    max-width: 155px;
    background-image: url(../images/profile-bg.jpg)
}

.minister .min-info {
    display: inline-block;
    padding: 10px 0 0px;
    width: 100%;
    font-size: 98%;
    text-align: center;
}

.minister-sub {
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
}

.minister-sub img {
    max-width: 100%;
}

.minister .min-info h4 {
    font-weight: 600;
}

.minister .min-info h5 {
    color: #000;
    padding-bottom: 0;
    font-size: 97%;
    font-weight: 600
}

.welcome-text,
.whats-new,
.minister {
    min-height: 300px
}

.scheme {
    width: 77.5%;
    float: left
}

.scheme h3 {
    color: #000;
    font-size: 140%;
    font-weight: 600;
    margin-bottom: 15px
}

.scheme-tab {
    width: 32%;
    margin-right: 2%;
    background: #e9e8e8;
    padding: 30px 20px;
    float: left;
    color: #FFF;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 130%
}

.scheme-tab:last-child {
    margin-right: 0
}

.bg-yellow {
    background: #f87218
}

.bg-green {
    background: #689512
}

.bg-red {
    background: #ea3535
}

.scheme-tab a.read-more {
    color: #fff;
    background: url(../images/blt-link.png) no-repeat scroll 10px 3px rgba(0, 0, 0, 0);
    text-decoration: none;
    font-size: 70%;
    padding-left: 30px;
    border-left: 1px solid #fff;
    display: inline-block;
    margin-left: 8%;
    font-weight: 400;
    text-transform: capitalize
}

.scheme-tab a.read-more:hover {
    text-decoration: underline
}

.quick-links-section {
    background: #f3f3f3;
    padding: 20px 0;
    margin-top: 30px
}

.quick-links-section * {
    transition: all .4s;
    -moz-transition: all .4s;
    -webkit-transition: all .4s
}

.quick-link {
    background: #fff;
    border: 1px solid #e0e0e0;
    position: relative;
    width: 30%;
    margin: 0 1.5% 10px;
    float: left;
    color: #000
}

.quick-link a {
    display: block;
    height: 66px;
    padding-left: 80px
}

.quick-link-icon {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 65px;
    display: block;
    background-color: #696969;
    background-repeat: no-repeat;
    background-position: center center
}

.right-arrow {
    position: absolute;
    right: -6px;
    top: 50%;
    margin-top: -6px;
    width: 6px;
    height: 12px;
    background: url(../images/icons/icon-1.png) no-repeat center center
}

.quick-link-text {
    font-size: 130%;
    padding-top: 17px;
    display: inline-block;
    color: #000
}

.icon1 {
    background-image: url(../images/icons/icon-1.png)
}

.icon2 {
    background-image: url(../images/icons/icon-1.png)
}

.icon3 {
    background-image: url(../images/icons/icon-1.png)
}

.icon4 {
    background-image: url(../images/icons/icon-1.png)
}

.icon5 {
    background-image: url(../images/icons/icon-1.png)
}

.icon6 {
    background-image: url(../images/icons/icon-1.png)
}

.quick-link:hover .quick-link-icon {
    background-color: #004242
}

.quick-link:hover .right-arrow {
    background: url(../images/icons/icon-1.png);
}

.quick-link:hover .quick-link-text {
    color: #004242
}

.scheme-right {
    width: 20%;
    float: right
}

.om-orders {
    background: #367588;
    padding: 20px
}

.om-orders a {
    display: block;
    background: url(../images/icons/icon-1.png);
    padding-left: 75px;
    color: #FFF;
    text-transform: uppercase;
    font-size: 120%;
    font-weight: 600;
    color: #FFF
}

.om-orders a input {
    display: block;
    background: #FFF;
    color: #1d1d1d;
    padding: 8px 10px;
    outline: none;
    border: none;
    width: 100%;
    margin-top: 5px
}

.om-orders:hover a input {
    background: #004242;
    color: #FFF
}

.related-links {
    padding-top: 30px
}

.related-links h3 {
    color: #000;
    font-size: 130%;
    font-weight: 600;
    margin-bottom: 15px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 5px
}

.related-links ul.list li {
    margin-bottom: 8px
}

.view {
    background: #0db363;
    color: #FFF;
    padding: 5px 20px;
    display: inline-block;
    font-size: 98%;
    margin-top: 18px
}

.view:hover {
    background-color: #004242
}

.home-btm-slider {
    background: #341f25
}

.img-cr {
    border-radius: 50%
}

.gallery-holder .flex-control-thumbs li:last-child {
    margin-bottom: 0
}

.ebook-maincontainer {
    width: 26%;
    float: right
}

.ebook-container {
    width: 100%;
    background: #FFF;
}

.publication {
    padding: 14px 20px;
    font-size: 16px
}

.logo a {
    color: #3457D3;
}

.logo a span {
    white-space: nowrap;
    font-size: 125%;
    text-transform: uppercase;
}

.logo img {
    float: left;
    padding: 0 21px 0 5px;
    position: relative;
    top: 6px;
}

.ebook-container .headdingimg {
    background: url(../images/ebook-heading.png) no-repeat;
    width: 50px;
    height: 24px;
    position: absolute
}

.ebook-container h3 {
    width: 100%;
    padding: 8px 20px;
    color: #fff;
    background: #32789d;
    font-weight: 400;
    font-size: 120%
}

.ebook-container .ebook-content {
    width: 100%;
    padding: 10px
}

.ebook-content img {
    float: left;
    padding-right: 10px;
    border-right: 1px solid #cac6c6;
    width: 50%
}

.ebook-content .explore {
    position: relative;
    width: 45%;
    float: right;
    bottom: 0;
    font-weight: 600
}

.explore .viewall {
    background: #32789d;
    padding: 6px 15px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    margin-top: 15px;
    display: inline-block
}

.explore .viewall:hover {
    background: #145171
}

.digital-container {
    width: 100%;
    padding: 10px;
    background: url(../images/nav-bg.jpg) repeat-x #cacaca;
    margin-top: 15px;
    display: block
}

.digital-container img {
    float: left
}

.digital-container .digital-content {
    width: 50%;
    float: right;
    font-weight: 600;
    color: #163344;
    margin-top: 30px
}

.white-container {
    width: 100%
}

.white-container .imp-link {
    float: none;
    width: 100%;
    padding: 30px 0
}

.white-container .visitor {
    float: right;
    width: 25%;
    padding: 30px 20px
}

.white-container .visitor img {
    margin-top: 20px
}

.imp-link .quicklink {
    width: 30%;
    float: left;
    padding-right: 20px
}

.imp-link .mediaunits {
    width: 68%;
    float: right;
    float: right;
    width: 49%
}

.imp-link .quicklink {
    float: left;
    padding-right: 20px;
    width: 25%
}

.imp-link .quicklink li a {
    color: #464748
}

.quicklink .implink-content {
    float: none;
    width: 100%
}

.quicklink .implink-content li {
    background: url(../images/ico-bullete.png) no-repeat 0 7px;
    padding-left: 14px
}

.white-container .visitor h3 {
    color: #000;
    font-size: 120%;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin-bottom: 20px
}

.imp-link .poorpatient .wuicklink {
    width: 100%
}

.poorpatient-content li {
    margin-bottom: 7px;
    width: 100%
}

.implink-content li a:hover {
    text-decoration: underline
}

.morebtt {
    display: inline-block;
    font-weight: 700;
    margin-top: 10px;
    padding: 8px 0;
    text-align: left
}

.morebtt:hover {
    text-decoration: underline
}

.imp-link .mediaunits h3,
.quicklink h3 {
    color: #000;
    font-size: 116%;
    font-weight: 600;
    text-align: left;
    width: 100%;
    margin-bottom: 20px
}

.imp-link .media-content {
    width: 50%;
    float: left;
    padding-right: 30px
}

.media-content li {
    margin-bottom: 7px;
    width: 100%
}

.media-content li a:hover {
    text-decoration: underline
}

.media-content .morebt {
    font-weight: 700
}

.media-content .morebt:hover {
    text-decoration: underline
}

.inner-banner {
    width: 100%
}

.inner-banner img {
    width: 100%
}

.inner-wrapper .container {
    width: 90%;
    margin: 0 auto
}

.breadcam-bg {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd
}

.main-menu>ul>li>a {
    padding: 5px 14px;
    min-height: 39px;
}

.main-menu ul>li a {
    color: #000;
    font-weight: 400;
    background: none
}

.main-menu ul>li>a {
    color: #333;
    font-weight: 600;
    text-transform: none
}

.main-menu ul>li {
    border: none
}

.main-menu>ul>li.active>a,
.main-menu>ul>li>a:focus,
.main-menu>ul>li:hover>a,
.main-menu>ul>li>a:hover,
.main-menu>ul>li>a.home,
.nav-item>a.open {
    /*background:#07477d;*/
    color: #3457D3!important;
    border-bottom: 3px solid #927148;
}

.sub-nav {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1)
}

.main-menu .sub-nav ul li a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0!important;
    color: #fff!important;
    font-weight: 400!important
}

.main-menu .sub-nav ul li {
    border-bottom: 1px solid rgba(0, 0, 0, 0)!important
}

.main-menu a.btn-more,
.main-menu a.btn-more:focus {
    background: #FFF!important;
    color: #000000 !important
}

.main-menu a.btn-more:focus,
.main-menu a.btn-more:hover {
    background: #3457D3!important
}

.main-menu a.btn-more.opened,
.main-menu a.btn-more.opened:hover,
.main-menu a.btn-more.opened:focus {
    background-color: #3457D3!important;
    color: #FFF!important
}

.sub-nav {
    background: #3457D3;
    color: #fff!important
}

#overflow_menu.main-menu ul>li {
    float: left;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.main-menu a.btn-more,
.main-menu a.btn-more:hover,
.main-menu a.btn-more:focus {
    padding: 0px 19px!important;
    border: 1px solid #3457D3;
}

.sub-nav .sub-sub-nav ul li {
    background: url(../images/ico-right-arrow.png) no-repeat left center;
    padding-left: 4px
}

.sub-nav li {
    background: rgba(0, 0, 0, 0) url(../images/ico-right-arrow.png) no-repeat scroll left 12px;
    padding-left: 4px
}

.main-menu .sub-nav-group>li>a {
    background: rgba(0, 0, 0, 1)
}

.main-menu .sub-nav ul li a {
    padding: 7px 10px
}

.social-feeds {
    background: url(../images/social-bg.jpg) repeat;
    padding: 40px 0
}

.social-feeds .panel-social {
    width: 50%;
    float: left;
    padding-right: 5%
}

.social-feeds .panel-feeds {
    width: 50%;
    float: right
}

.panel-feed {
    float: right;
    width: 50%;
    padding-left: 5%
}

.social-feeds h3 {
    color: #000;
    font-size: 160%;
    font-weight: 600;
    margin-bottom: 15px;
    padding-bottom: 8px
}

.padding-top-bott {
    padding-top: 50px;
    padding-bottom: 50px
}

.resp-tabs-list li {
    font-size: 15px;
    display: inline-block;
    /*padding:12px 26px;*/
    list-style: none;
    cursor: pointer;
    float: left;
    background: #3457D3
}

.resp-tabs-list li {
    margin-right: 5px;
    background-color: #3457D3;
}

.resp-tabs-list li:last-child {
    margin-right: 0;
}

.resp-tabs-container {
    padding: 0;
    background-color: #fff;
    clear: left;
    /*border:1px solid #e6e6e6!important;border-top-color:#fff!important*/
}

h2.resp-accordion {
    cursor: pointer;
    padding: 5px;
    display: none
}

.resp-tab-content {
    display: none;
    padding: 15px;
    min-height: 212px;
    position: relative;
    margin-top: -1px
}

.resp-tab-active {
    border-bottom: none;
    border-bottom: none;
    background-color: #fff
}

.resp-content-active,
.resp-accordion-active {
    display: block
}

.resp-tab-content a.moreBtn {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background: #2a8981;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    color: #fff;
    border: 0;
    font-size: 87.5%;
    padding: 5px 8px
}

.resp-tab-content a.moreBtn:hover {
    background: #333;
    color: #fff
}

h2.resp-accordion {
    font-size: 13px;
    border: 1px solid #c1c1c1;
    border-top: 0 solid #c1c1c1;
    margin: 0;
    padding: 10px 15px
}

h2.resp-tab-active {
    border-bottom: 0 solid #c1c1c1!important;
    margin-bottom: 0!important;
    padding: 10px 15px!important
}

h2.resp-tab-title:last-child {
    border-bottom: 12px solid #c1c1c1!important;
    background: blue
}

.resp-vtabs ul.resp-tabs-list {
    float: left;
    width: 30%
}

.resp-vtabs .resp-tabs-list li {
    display: block;
    padding: 15px!important;
    margin: 0 0 4px;
    cursor: pointer;
    float: none
}

.resp-vtabs .resp-tabs-container {
    padding: 0;
    background-color: #fff;
    border: 1px solid #c1c1c1;
    float: left;
    width: 68%;
    min-height: 250px;
    border-radius: 4px;
    clear: none
}

.resp-vtabs .resp-tab-content {
    border: none;
    word-wrap: break-word
}

.resp-vtabs li.resp-tab-active {
    position: relative;
    z-index: 1;
    margin-right: -1px!important;
    padding: 14px 15px 15px 14px !important;
    border-top: 1px solid;
    border: 1px solid #5AB1D0!important;
    border-left: 4px solid #5AB1D0!important;
    margin-bottom: 4px!important;
    border-right: 1px #FFF solid!important
}

.resp-arrow {
    width: 0;
    height: 0;
    float: right;
    margin-top: 3px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid #c1c1c1
}

h2.resp-tab-active span.resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid #9B9797
}

h2.resp-tab-active {
    background: #DBDBDB
}

.resp-easy-accordion h2.resp-accordion {
    display: block
}

.resp-easy-accordion .resp-tab-content {
    border: 1px solid #c1c1c1
}

.resp-easy-accordion .resp-tab-content:last-child {
    border-bottom: 1px solid #c1c1c1
}

.resp-jfit {
    width: 100%;
    margin: 0
}

.resp-tab-content-active {
    display: block
}

h2.resp-accordion:first-child {
    border-top: 1px solid #c1c1c1
}

.resp-tab-content ul.list li {
    display: block;
    padding: 9px 10px;
    background: url(../images/ico-bullete.png) left 17px no-repeat;
    padding-left: 20px;
    color: #000;
    line-height: 160%;
    /* border-bottom: 1px solid #ddd; */
}

.resp-tab-content ul.list li a {
    color: #000
}

.resp-tab-content ul.list li a:hover {
    color: #a9a9a9
}

.resp-tab-content ul.list li span {
    font-weight: 600;
    display: block;
    padding-bottom: 10px
}

.resp-tab-content ul.list li p {
    font-weight: 400;
    display: block;
    font-size: 98%
}

.resp-tab-content {
    min-height: 461px
}

.newsTickers ul {
    width: 100%
}

.panel-social .resp-tabs-list li a {
    outline: none
}

.panel-social .resp-tabs-list li {
    display: inline-block;
    padding: 10px 25px;
    margin: 0;
    background: none!important;
    position: relative;
    z-index: 10;
    border: 1px solid transparent!important
}

.panel-social .resp-tabs-list li.resp-tab-active {
    background: #fff!important;
    border-top: 1px solid #d9dde0!important;
    border-left: 1px solid #d9dde0!important;
    border-right: 1px solid #d9dde0!important
}

.panel-social .resp-tabs-container {
    background: #fff;
    border-bottom: 1px solid #d9dde0;
    border-top: 1px solid #d9dde0!important;
    border-left: 1px solid #d9dde0!important;
    border-right: 1px solid #d9dde0!important;
    margin-top: -1px;
    z-index: 9
}

.resp-tabs-list li a {
    outline: none;
    color: #FFF
}

.resp-tabs-list li.resp-tab-active a {
    color: #3457D3
}

.panel-feed .resp-tabs-list li a {
    font-size: 85%;
    font-weight: 600
}

.panel-feed .resp-tabs-list li a i {
    font-size: 120%;
    font-weight: 600;
    display: inline-block;
    padding-right: 5px
}

.panel-feed .resp-tab-active a {
    color: #f60
}

.panel-feed .bttn {
    background: none;
    color: #32789d;
    font-weight: 600;
    text-transform: uppercase
}

.socialTab_1 iframe {
    height: 430px!important
}

.item-list {
    margin: 0;
    padding: 0
}

.item-list li {
    display: block;
    background-color: #4c4d52;
    margin-bottom: 20px
}

.item-list li:last-child {
    margin-bottom: 0;
}

.b-bg {
    background-color: #FF5722
}

.g-bg {
    background-color: #02a17a
}

.y-bg {
    background-color: #FF9800
}

.y_d-bg {
    background-color: #73a737
}

.icon,
.text-cont {
    padding: 18px;
    display: table-cell;
    vertical-align: middle;
    color: #fff
}

.text-sm {
    font-size: 1.1em;
    font-weight: 600
}

.text-lg {
    font-size: 1.8em;
    font-weight: 300
}

.imp-link {
    padding: 1.5em;
    float: left;
    width: 100%;
    margin-top: 30px;
    background-color: #fff;
    border: 1px solid #e1dddd
}

ul.ul-v>li {
    display: inline-block;
    padding-right: 97px;
}

ul.ul-v>li:last-child {
    padding-right: 0;
}

.imp-link ul.ul-v>li {
    background-image: url(../images/icons/icon-1.png);
    background-repeat: no-repeat;
    background-position: left 12px
}

ul.ul-v>li a {
    color: #0d0e44;
    font-size: 16px
}

.left-block {
    float: left;
    width: 72.5%
}

.imp-img-cir ul.ul-v>li {
    /*width:16.8%;*/
    width: auto;
    margin-right: -5px;
    vertical-align: top
}

.imp-img-cir ul.ul-v>li a .img-cr {
    /*background:#07477d;*/
    width: 70px;
    padding-top: 10px;
    height: 70px;
    display: block;
    margin: 0 auto;
    margin-bottom: 10px
}

.imp-img-cir ul.ul-v>li h5 {
    font-weight: 600
}

.imp-img-cir ul.ul-v>li a:hover .img-cr,
.imp-img-cir ul.ul-v>li a:hover {
    /*background:#000;*/
    color: #000;
}

.imp-img-cir ul.ul-v>li a,
.imp-img-cir ul.ul-v>li {
    color: #fff;
}

.footer-top-wrapper ul li a,
.copyright-content span {
    color: #eee
}

.footer-top-wrapper ul li:hover a {
    color: #fff;
    text-decoration: underline;
}

.inner-content h1 {
    font-size: 2.2em;
    margin-bottom: 1em
}

.inner-content h3 {
    font-size: 1.3em;
    font-family: sans-serif
}

.new-letter {
    width: 100%;
    display: table;
    font-size: 1.2em
}

.new-letter .new-icon,
.new-letter .new-text {
    display: table-cell;
    vertical-align: middle;
    background-color: #fff
}

.new-letter .new-text {
    padding: 5px 0 0;
}

.new-letter .new-text .subscibe-now {
    padding: 5px 12px;
    background-color: #4c4d52;
    color: #fff;
    margin-top: 5px;
}

.new-letter .new-icon {
    padding: 1em;
    background-color: #937048;
    text-align: center
}

.new-letter .new-text small,
.new-letter .new-text big {
    display: block;
    padding: 5px 12px;
    font-weight: 600
}

.banner-wrapper .flex-control-nav {
    width: auto;
    position: absolute;
    bottom: 25px;
    text-align: left;
    background: #4b4b4b;
    padding: 0.52em;
    line-height: 0;
    left: 120px;
    display: none;
}

.banner-wrapper .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    padding: 7px;
}

.banner-wrapper .flex-pauseplay {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 8.15%;
    z-index: 1000;
    display: block;
    width: 40px;
    height: 40px;
    margin-top: 0;
    margin-left: 0;
    background: #937048;
}

.flex-pauseplay a {
    bottom: 11px !important;
    opacity: 1;
    line-height: inherit;
    height: 23px;
}

.flex-control-paging li a.flex-active {
    background: #07477d;
}

.banner-wrapper .flex-pauseplay a {
    /*background: rgba(55, 55, 55, 1);*/
    color: #fff;
    opacity: 1;
    border-radius: 0;
}

.video_link {
    width: 100%;
    background: #222;
    font-size: 1.2em;
}

.video_link a {
    display: block;
    width: 100%;
}

.video_link a:hover,
.video_link a:hover .video-icon {
    background: #000;
}

.pub-btn .video-icon,
.pub-btn {
    background: #937048
}

.pub-btn a {
    display: block;
    width: 100%;
    font-size: 1.2em;
}

.video-icon,
.video-text {
    display: table-cell;
    vertical-align: middle;
    color: #fff;
    padding: 10px 5px;
}

.video-icon {
    background: #222;
    text-align: center;
}

.video-icon,
.new-letter .new-icon {
    padding-left: 20px;
}


/*==================*/

.url {
    color: #4211df;
    font-size: 12px;
    font-weight: normal;
}

.resultBody {
    padding-bottom: 1%;
}

.resultSummary {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.previousLink {
    cursor: pointer;
}

.nextLink {
    cursor: pointer;
}

.navigationLink {
    cursor: pointer;
    margin: 4px;
}

.navigationHTML {
    text-decoration: none;
    color: #15B;
}

.contentType {
    font-size: 11px;
    text-decoration: none;
    font-weight: normal;
    color: #4211df;
}

.page-goisearch #page-title {
    width: 100%;
    float: left;
}

.result-page-form {
    width: 100%;
    float: left;
    margin-bottom: 14px;
}

.result-page-form input[type="text"] {
    border: 1px solid #ccc;
    color: #0d564f;
    float: left;
    margin: 0;
    padding: 6px;
    text-transform: uppercase;
    width: 214px;
}

.result-page-form input[type="button"] {
    border: 1px solid #ccc;
    color: #0d564f;
    float: left;
    margin: 2px 0 0 9px;
    cursor: pointer;
    padding: 6px;
    text-transform: uppercase;
    width: 104px;
}

.content {
    /*width: 100%;	
	font-size: 13px;
	font-weight: normal;
	color: #000000;
	margin: 0;
	word-wrap:break-word;*/
}

.result {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px dotted #a1a1a1;
    display: block;
}

.pageDetails {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.totalresult {
    color: #722C78;
    font-size: 11px;
    font-weight: normal;
    line-height: 13px;
}

.query {
    color: #6E0078;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.absentResultBody {
    font-size: 12px;
    font-weight: normal;
}

.spellCheckerMain {
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
}

.spellChecker {
    color: #000000;
}

.spellCheckSuggestion {
    color: #FF0000;
    text-decoration: underline;
    CURSOR: pointer;
}

.feedbackImg {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid;
    border-color: #1E90FF;
    height: 14px;
    width: 14px;
}

.docDate {}

.currentLink {
    color: #000000;
    font-weight: bold;
}

em {
    font-style: normal;
    font-weight: bold;
}

.result-page-form .main-goi-form {
    float: left;
    width: 100%;
}

.find form {
    width: 270px;
    padding: 0;
}

.find #auto_suggesion {
    margin-left: 0px;
    margin-top: 0px;
    max-width: 270px;
    position: absolute;
    z-index: 99999;
    width: 270px;
    background-color: #ffffff;
}

#auto_suggesion {
    margin-left: 465px;
    margin-top: 37px;
    max-width: 210px;
    position: absolute;
    z-index: 99999;
    display: none;
}

#auto_suggesion ul {
    background: #e1e1e1 none repeat scroll 0 0;
    border: 1px solid #000;
    visibility: visible;
    padding: 5px;
    top: 0;
    width: 100%;
}

#auto_suggesion li {
    border-left: none!important;
    width: 100%;
    padding: 2px 0 2px 3px;
    margin: 0;
    list-style: none;
}

.result-page-form #auto_suggesion {
    margin-left: 0px!important;
    min-width: 305px;
}

.result-page-form #auto_suggesion ul {
    background: none repeat scroll 0 0 #e1e1e1;
    border: 1px solid #ccc;
}

.common-right ul li.ico-site-search input[type="text"],
.main-goi-form input[type="text"] {
    border: 2px solid #aa2323;
    height: 36px;
    line-height: 34px;
    padding: 0 40px 0 8px;
    width: 270px;
    background: url(../images/menu-bullet.png) #fff no-repeat 8px 12px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}

.common-right ul li.ico-site-search input[type="text"]:focus,
.main-goi-form input[type="text"] {
    background: #fff;
}

.find {
    position: relative;
}

.find .bttn-search,
.find .bttn-search:hover {
    width: 34px;
    height: 36px;
    cursor: pointer;
    border: 0px;
    overflow: hidden;
    text-indent: -200px;
    font-size: 1px;
    background: url(../images/ico-goi-search.png) no-repeat center center #aa2323;
    position: absolute;
    top: 0px;
    left: 229px;
    padding: 5px 20px;
    margin: 0;
}

.main-goi-form input[type="button"] {
    width: 34px;
    height: 36px;
    cursor: pointer;
    border: 0px;
    overflow: hidden;
    text-indent: -200px;
    font-size: 1px;
    background: url(../images/ico-goi-search.png) no-repeat center center #aa2323;
    padding: 0;
    margin: 0;
}

.bttn-search:hover {
    background-color: #c22929;
}

.common-right #auto_suggesion ul li a {
    color: #000;
    width: 100%;
}

.page-taxonomy .search-drop form {
    margin: 0;
}

.main-goi-form>a {
    margin-left: 20px;
}

.main-goi-form>a>img {
    margin-top: 14px;
}

.navigationHTML span {
    background-color: #e1e1e1;
    padding: 0 10px;
}

.navigationHTML .navigationLink {
    margin: 0 15px;
}


/*.resp-tabs-list {
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
*/

.resp-tabs-list li.resp-tab-active {
    border-color: #fff;
}

.resp-tabs-list li {
    padding: 14px 24px;
    border-top: 10px solid #ebebeb;
    /*width: 200px;
  min-width: 170px;
  -webkit-flex: initial;
          flex: initial;*/
}

.resp-tabs-list li:first-child {
    margin-left: 0px;
}

.resp-tabs-list li:last-child {
    margin-left: 0px;
    /*width: 250px;
  min-width: 220px;*/
}

.four_content .common-left ul li {
    padding-top: 6px;
}

.r-complain {
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: -110px;
    width: 100%;
}

.r-rc {
    background: #341f25;
    height: 120px;
    color: #d8a56a;
    padding: 25px 30px;
}

.r-cs {
    background: #937048;
    height: 120px;
    color: #341f25;
    padding: 37px 30px;
}

.r-cs form {
    position: relative;
    display: inline-block;
    width: auto;
    line-height: 32px;
}

.r-cs .search {
    border: none;
    height: 38px;
    line-height: 34px;
    padding: 0 40px 0 8px;
    width: 251px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    margin-top: -1px;
}

.r-cs .bttn-search,
.r-cs .bttn-search:hover {
    width: 34px;
    height: 36px;
    cursor: pointer;
    border: 0px;
    overflow: hidden;
    text-indent: -200px;
    font-size: 1px;
    background: url(../images/search.png) no-repeat center center #FFF;
    position: absolute;
    top: 0px;
    left: 210px;
    padding: 5px 20px;
    margin: 0;
}

#counter {
    text-align: center;
}

#counter .count {
    font-size: 60px;
    color: #937048;
}

h2 {
    font-size: 30px;
    color: #464545;
    padding-top: 40px;
    clear: both;
}

h2 small {
    display: block;
    line-height: 275%;
    font-size: 50%;
}

#counter p {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
}

.common-left a {
    display: block;
    color: #333;
}

iframe img {
    width: 100%!important;
}